import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import { Subtitle3, Subtitle1 } from 'pharmacy/src/typography'
import { Link } from 'pharmacy/src/navigation/link'

import _ from 'lodash'

import css from './questionSponsorship.scss'

class QuestionSponsorship extends React.Component {
  render = () => {
    const { sponsorships, stubbed, fullScreen } = this.props

    if (!sponsorships?.length) {
      return null
    }

    const activeSponsorships = sponsorships?.filter((sponsorship) =>
      moment(new Date()).isBetween(sponsorship.startDate, sponsorship.endDate)
    )

    if (!activeSponsorships?.length) {
      return null
    }

    const sponsorship = _.orderBy(activeSponsorships, ['endDate'], ['desc'])[0]

    const { type, topic, sponsor, useCustomInfo, infoText } = sponsorship

    if (type === 'topic') {
      if (!stubbed) {
        return (
          <div className={[css.sponsorContainer, css.print].join(' ')}>
            <hr className={fullScreen ? css.grayDivider : css.divider} />
            {useCustomInfo ? (
              <Subtitle3
                className={css.sponsorText}
                dangerouslySetInnerHTML={{ __html: infoText }}
              />
            ) : (
              <>
                <Subtitle3 className={css.sponsorText}>
                  This question is part of a special educational program about{' '}
                  {topic} supported by:
                </Subtitle3>
                <Subtitle1 className={css.sponsorSponsor}>
                  {sponsor.sponsor}
                </Subtitle1>
                <Subtitle3 className={css.sponsorText}>
                  {sponsor.shortName} had no input on the questions or answers
                  in this session. Learn more about these programs{' '}
                  <Link
                    pathname="/site/SEP"
                    external
                    className={css.sponsorLink}
                  >
                    here
                  </Link>
                  .
                </Subtitle3>
              </>
            )}
          </div>
        )
      }
      return (
        <>
          <Subtitle3 className={css.sponsorText}>
            This question is part of a special educational program about {topic}
          </Subtitle3>
          <Subtitle1 className={css.sponsorSponsor}> </Subtitle1>
          <Subtitle3 className={css.sponsorText}>
            Learn more about these programs{' '}
            <Link pathname="/site/SEP" external className={css.sponsorLink}>
              here
            </Link>
            .
          </Subtitle3>
        </>
      )
    }

    return null
  }
}

const mapStateToProps = (state, ownProps) => {
  const { questionId } = ownProps
  const questionData = state.question.questions[questionId]
  const { sponsorships } = questionData

  return {
    sponsorships,
  }
}

export default connect(mapStateToProps)(QuestionSponsorship)
