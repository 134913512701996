import React from 'react'
import classNames from 'classnames'

import { UserImage } from 'pharmacy/src/user/userImage/index'
import { IMAGE_SIZE } from 'mednet-util/src/router'

import css from './userImageContainer.scss'

const imageSizeClass = {
  [IMAGE_SIZE.MEDIUM]: css.userInformationImage_medium,
  [IMAGE_SIZE.BIG]: css.userInformationImage_big,
  [IMAGE_SIZE.HEADER]: css.userInformationImage_header,
}

class UserImageContainer extends React.Component {
  static defaultProps = {
    imageSize: IMAGE_SIZE.MEDIUM,
  }

  render() {
    const {
      className,
      user,
      imageSize,
      useUserProfileLink,
      onProfileClick,
      children,
    } = this.props
    const { userId, imageVersion, isAnonymized } = user || {}

    const containerClasses = classNames(css.userInformationContainer, className)

    return (
      <div className={containerClasses}>
        <UserImage
          className={imageSizeClass[imageSize]}
          userId={isAnonymized ? null : userId}
          imageVersion={imageVersion}
          imageSize={imageSize}
          useUserProfileLink={useUserProfileLink}
          onProfileClick={onProfileClick}
        />
        {children}
      </div>
    )
  }
}

export default UserImageContainer
