import React from 'react'
import _ from 'lodash'

import { HomePage } from 'pages/home'
import { TopicPage } from 'pages/topic'
import { ManageQuestionAnswersPage } from 'pages/manageQuestionAnswers'
import { QuestionPage, AskQuestionPage, EditAnswerPage } from 'pages/question'
import { ManageCustomExpertEmailPage } from 'pages/manageCustomExpertEmail'
import { ManageAnswerCommentsPage } from 'pages/manageAnswerComments'
import { ManageDigestPage } from 'pages/manageDigest'
import { UserPage } from 'pages/user'
import { About } from 'pages/about'
import { TumorBoard } from 'pages/tumorBoard'
import { Experts } from 'pages/experts'
import { Editors } from 'pages/editors'
import { Contact } from 'pages/contact'
import { Registration } from 'pages/registration'
import { PublicHome } from 'pages/publicHome'
import { Partnerships } from 'pages/partnerships'
import { PublicFAQ } from 'pages/publicFAQ'
import { Programs } from 'pages/programs'
import { PasswordRecovery } from 'pages/passwordRecovery'
import { UserSearchPage } from 'pages/userSearch'
import { SystemJobsPage } from 'pages/systemJobs'
import { CampaignPage } from 'pages/campaign'
import DynamicTemplatesPage from 'pages/templates/dynamic'
import { CmeDashboard } from 'pages/cme'
import { Fellows } from 'pages/fellows'

import {
  SponsorshipDashboard,
  InjectedContentDashboard,
  InjectionDashboard,
  ViewSponsorshipPage,
  SponsorshipCampaignsPage,
  SponsorshipEmailsPage,
  SponsorshipSendEmailsPage,
  SponsorshipAnswerPage,
} from 'pages/sponsorships'

import { PAGES } from 'constants/pages'

import { SposorshipCme } from 'pages/cme/sponsorshipCme'
import UserMoc from 'pages/user/userMoc'

import { Login } from 'pages/login'
import { CommunityOverview } from 'pages/communityOverview'
import { NewsReleases } from 'pages/newsReleases'
import { SubmitInvitesUnsubscription } from 'pages/stopInvites'
import { ResubscribeExpertEmails } from 'pages/user/notificationPermissions/resubscribeExpertEmails'

const ViewUserPage = (props) => <UserPage {...props} showMessage showEdit />

const mainPages = {
  [PAGES.HOME]: {
    path: '/home/index',
    component: HomePage,
  },
  [PAGES.ASK_QUESTION]: {
    path: '/question/ask',
    component: AskQuestionPage,
  },
  [PAGES.VIEW_TOPIC]: {
    path: '/topic/:topicId',
    component: TopicPage,
  },
  [PAGES.VIEW_QUESTION]: {
    path: '/question/:questionId',
    component: QuestionPage,
  },
  [PAGES.VIEW_USER]: {
    path: '/user/user/view/id/:userId',
    render: ViewUserPage,
  },
  [PAGES.EDIT_ANSWER]: {
    path: '/answer/edit/:answerId',
    component: EditAnswerPage,
  },
  [PAGES.VIEW_CAMPAIGN_QUESTION]: {
    path: '/campaign/question/:hash/:campaignId/:questionId',
    component: QuestionPage,
  },
  [PAGES.SUBSCRIBE_EXPERT_EMAILS]: {
    path: '/user/:userId/notificationPermissions/resubscribeExpertEmails',
    component: ResubscribeExpertEmails,
  },
}

const moderatorManagePages = {
  [PAGES.MANAGE_QUESTION_ANSWERS]: {
    path: '/question/manageAnswers/:questionId',
    component: ManageQuestionAnswersPage,
    label: 'Review Answers',
  },
  [PAGES.MANAGE_EXPERT_EMAIL]: {
    path: '/expertEmail/manage',
    component: ManageCustomExpertEmailPage,
  },
  [PAGES.MANAGE_ANSWER_COMMENTS]: {
    path: '/answer/manageComments/:answerId',
    component: ManageAnswerCommentsPage,
  },
  [PAGES.MANAGE_DIGEST]: {
    path: '/digest/manage',
    component: ManageDigestPage,
    label: 'Manage Digest',
  },
  [PAGES.USER_SEARCH]: {
    path: '/user/search',
    component: UserSearchPage,
  },
  [PAGES.SYSTEM_JOBS]: {
    path: '/system/jobs',
    component: SystemJobsPage,
  },
  [PAGES.CAMPAIGN]: {
    exact: true,
    path: '/campaign/:action/:id?',
    component: CampaignPage,
  },
  [PAGES.INVITATION]: {
    exact: true,
    path: '/invitation/:action/:id?',
    component: CampaignPage,
  },
  [PAGES.TEMPLATES_PREVIEW]: {
    path: '/templates/preview',
    component: DynamicTemplatesPage,
  },
  [PAGES.MOC_PROFILE_BY_ID]: {
    path: '/user/mocProfile/:userId',
    component: UserMoc,
  },
}

const moderatorSponsorshipPages = {
  [PAGES.SPONSORSHIP_DASHBOARD]: {
    path: '/sponsorship/dashboard',
    component: SponsorshipDashboard,
    label: 'Sponsorships Dashboard',
  },
  [PAGES.SPONSORSHIP_INJECTED_CONTENT_DASHBOARD]: {
    path: '/sponsorship/stats',
    component: InjectedContentDashboard,
    label: 'Sponsorships Injected Content Stats Dashboard',
  },
  [PAGES.SPONSORSHIP_INJECT_CONTENT]: {
    path: '/sponsorship/inject',
    component: InjectionDashboard,
    label: 'Sponsorships Force Injection Dashboard',
  },
  [PAGES.VIEW_SPONSORSHIP]: {
    path: '/sponsorship/view/:sponsorshipId',
    component: ViewSponsorshipPage,
  },
  [PAGES.SPONSORSHIP_MANAGE_CAMPAIGNS]: {
    path: '/sponsorship/campaigns/:sponsorshipId',
    component: SponsorshipCampaignsPage,
  },
  [PAGES.SPONSORSHIP_MANAGE_EMAILS]: {
    path: '/sponsorship/emails/:sponsorshipId',
    component: SponsorshipEmailsPage,
    label: 'Manage Emails',
  },
  [PAGES.SPONSORSHIP_SEND_EMAIL]: {
    path: '/sponsorship/sendEmail/:sponsorshipId',
    component: SponsorshipSendEmailsPage,
  },
  [PAGES.SPONSORSHIP_ANSWER]: {
    path: '/sponsorship/answer/:sponsorshipId',
    component: SponsorshipAnswerPage,
  },
}

const landingPages = {
  [PAGES.ABOUT_US]: {
    path: '/about',
    component: About,
  },
  [PAGES.TUMORBOARD]: {
    path: '/tumorboard',
    component: TumorBoard,
  },
  [PAGES.EXPERTS]: {
    path: '/experts',
    component: Experts,
  },
  [PAGES.EDITORS]: {
    path: '/editors',
    component: Editors,
  },
  [PAGES.FELLOWS]: {
    path: '/fellows',
    component: Fellows,
  },
  [PAGES.CONTACT_US]: {
    path: '/contact',
    component: Contact,
  },
  [PAGES.REGISTRATION]: {
    path: '/register',
    component: Registration,
  },
  [PAGES.PUBLIC_HOME]: {
    exact: true,
    path: '/',
    component: PublicHome,
  },
  [PAGES.PARTNERSHIPS]: {
    path: '/partnerships',
    component: Partnerships,
  },
  [PAGES.FAQ]: {
    path: '/faq',
    component: PublicFAQ,
  },
  [PAGES.PROGRAMS]: {
    path: '/programs',
    component: Programs,
  },
  [PAGES.PASSWORD_RECOVERY]: {
    path: '/recoverpassword',
    component: PasswordRecovery,
  },
  [PAGES.LOGIN]: {
    path: '/user/login',
    component: Login,
  },
  [PAGES.COMMUNITY_OVERVIEW]: {
    path: '/communityOverview',
    component: CommunityOverview,
  },
  [PAGES.NEWS_RELEASES]: {
    path: '/pressRelease',
    component: NewsReleases,
  },
}

const cmePages = {
  [PAGES.CME_DASHBOARD]: {
    path: '/cme',
    component: CmeDashboard,
  },
  [PAGES.SPONSORSHIP_ANSWERS_CME]: {
    path: '/sponsorship/cme/:sponsorshipId',
    component: SposorshipCme,
  },
  [PAGES.MOC_PROFILE]: {
    path: '/user/mocProfile',
    component: UserMoc,
  },
}

const unregisteredUserPages = {
  [PAGES.STOP_INVITES]: {
    path: '/stopInvites',
    component: SubmitInvitesUnsubscription,
  },
}

export const ROUTES = _.assign(
  {},
  moderatorManagePages,
  moderatorSponsorshipPages,
  landingPages,
  cmePages,
  mainPages,
  unregisteredUserPages
)
