import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'

import { Subtitle3 } from 'pharmacy/src/typography'
import { actionVerbNames } from 'mednet-util/src/constants/questionUpdate'
import { UserDescription } from 'pharmacy/src/user/userDescription'

import css from './userUpdateText.scss'

class UserUpdateText extends React.Component {
  render() {
    const {
      user,
      dateUpdated,
      actionId,
      secondaryActionId,
      secondaryDateUpdated,
      ...restProps
    } = this.props

    if (_.isEmpty(user) && !dateUpdated) {
      return null
    }

    return (
      <div>
        <UserDescription user={user} {...restProps} />
        {dateUpdated && (
          <div className={css.updateDateContainer}>
            <Subtitle3 className={css.createdDate}>
              {actionVerbNames[actionId]} on{' '}
              {moment(dateUpdated || undefined).format('MMMM D YYYY')}
            </Subtitle3>
            {secondaryActionId && (
              <Subtitle3>
                {actionVerbNames[secondaryActionId]} on{' '}
                {moment(secondaryDateUpdated || undefined).format(
                  'MMMM D YYYY'
                )}
              </Subtitle3>
            )}
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: ownProps.user || state.user.users[ownProps.userId] || {},
  }
}

export default connect(mapStateToProps)(UserUpdateText)
