import React, { useCallback, useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import * as yup from 'yup'
import classNames from 'classnames'
import { useDispatch } from 'react-redux'
import { FastField, Formik, Form } from 'formik'
import isEmpty from 'lodash/isEmpty'

import { Button } from 'pharmacy/src/input/button'
import { ProximaNova } from 'pharmacy/src/typography'
import { TextInput } from 'pharmacy/src/input/textInput'
import { Link } from 'pharmacy/src/navigation/link'

import { mixpanel } from 'mednet-util/src/tracking'

import { login } from 'mednet-cns/src/reducers/user'

import { TitledSection } from 'components/titledSection'

import css from './loginForm.scss'

const loginSchema = yup.object().shape({
  // We are adding strip to the end of the email-schema to ignore accidental spaces
  // doc: https://github.com/jquense/yup#schemastripenabled-boolean--true-schema
  username: yup.string().required('Required').email('Invalid email').trim(),
  password: yup.string().required('Required'),
})

const getValidationTag = (fieldName, touched, errors) =>
  touched[fieldName] && errors[fieldName] ? errors[fieldName] : undefined

const TextInputField = ({ name, touched, errors, ...restProps }) => {
  const validationError = getValidationTag(name, touched, errors)

  return (
    <FastField name={name}>
      {({ field }) => (
        <TextInput
          className={classNames(css.loginFormField, {
            [css.invalidLoginFormField]: validationError,
          })}
          {...field}
          {...restProps}
          typeStyle="proximaNovaPrimary"
          showClear={false}
          onChangeHandlesEvent
          validationTag={validationError}
        />
      )}
    </FastField>
  )
}

const handleLoginFormSubmission = (
  values,
  dispatch,
  setSubmitting,
  setLoginStatus,
  setErrors
) => {
  dispatch(
    login(
      { ...values, distinct_id: mixpanel.get_distinct_id() },
      (response) => {
        setSubmitting(false)

        if (response.errors) {
          setErrors({
            [Object.keys(response.errors)[0]]: Object.values(
              response.errors
            )[0],
          })
        } else {
          setLoginStatus(true)
        }
      }
    )
  )
}

const InterenalLoginForm = (props) => {
  const {
    isSubmitting,
    touched,
    errors,
    handleSubmit,
    values,
    setErrors,
    history,
  } = props

  const trackValidationFailure = useCallback(() => {
    if (!isEmpty(errors)) {
      mixpanel.track('Login Attempt - JS Validation Failure', {
        loginEmail: values.username,
        errors,
      })
    }
  }, [errors, values.username])

  useEffect(trackValidationFailure, [!isEmpty(errors) && errors])

  useEffect(() => {
    setErrors({})
  }, [values])

  return (
    <Form>
      <TextInputField
        name="username"
        placeholder="Email"
        touched={touched}
        errors={errors}
      />

      <TextInputField
        name="password"
        placeholder="Password"
        touched={touched}
        errors={errors}
        isPassword
      />

      <Button
        type="orange"
        className={css.loginFormButton}
        onClick={handleSubmit}
        isLoading={isSubmitting}
        submitButton
      >
        <ProximaNova.Text2>Sign In</ProximaNova.Text2>
      </Button>

      <Link
        className={classNames(
          css.loginFormHelperQuestion,
          css.forgotPasswordLink
        )}
        pathname="/recoverpassword"
      >
        Forgot password?
      </Link>

      <hr className={css.loginFormHorizontalLine} />

      <div className={css.loginFormRegisterOptionContainer}>
        <div className={css.loginFormHelperQuestion}>
          Don&rsquo;t have an account?
          <Button
            type="dark_blue"
            size="small"
            pathname="/register"
            params={{
              src: 'login',
              src_path: history?.location?.pathname,
              src_item: 'login',
            }}
            className={css.loginFormRegisterButton}
          >
            <div className={css.loginFormRegisterButtonText}>Register</div>
          </Button>
        </div>
      </div>
    </Form>
  )
}

export const LoginForm = ({ history }) => {
  const [loggedInSuccessfully, setLoginStatus] = useState(false)

  const dispatch = useDispatch()

  const initialLoginFormValues = {
    username: undefined,
    password: undefined,
  }

  if (loggedInSuccessfully) {
    const fromRedirectParam = new URLSearchParams(window.location.search).get(
      'from'
    )
    const redirectUrl = fromRedirectParam ?? '/home/index'
    return <Redirect to={redirectUrl} />
  }

  return (
    <div className={css.loginFormContainer}>
      <TitledSection header3="Sign In" headerLeftAligned>
        <Formik
          initialValues={initialLoginFormValues}
          validationSchema={loginSchema}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            handleLoginFormSubmission(
              values,
              dispatch,
              setSubmitting,
              setLoginStatus,
              setErrors
            )
          }}
        >
          {(props) => <InterenalLoginForm {...props} history={history} />}
        </Formik>
      </TitledSection>
    </div>
  )
}
