import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'

import { Subtitle1, Subtitle3, Body1 } from 'pharmacy/src/typography'
import { SimpleSelect } from 'pharmacy/src/input/select'
import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'
import { Button } from 'pharmacy/src/input/button'
import { refresh } from 'mednet-util/src/router'

import { AnswerInformation } from 'pharmacy/src/answer/answerInformation'
import {
  FETCH_CAMPAIGN_QUESTION_ANSWERS,
  FETCH_QUESTION_ANSWERS,
} from 'mednet-cns/src/reducers/question'
import { makeRequestName } from 'mednet-cns/src/reducers/request'
import { ErrorBoundary } from 'pharmacy/src/misc/errorBoundary'
import { withPermissions } from 'mednet-util/src/permission'
import { authItems } from 'mednet-util/src/constants/permission'

import css from './questionAnswers.scss'

const ManageAnswersButton = withPermissions(authItems.updateAnswer)(
  ({ questionId }) => (
    <Subtitle3>
      <Button
        className={css.manageButton}
        pathname={`/question/manageAnswers/${questionId}`}
        icon="cog"
        type="secondaryText"
        external
      >
        Manage
      </Button>
    </Subtitle3>
  )
)

class QuestionAnswers extends React.Component {
  constructor(props) {
    super(props)
    this.scrollToRef = React.createRef()

    this.state = {
      sortOrder: 'best',
    }
  }

  componentDidUpdate = () => {
    if (!this._scrolled) {
      this.scrollToActiveAnswer()
      this._scrolled = true
    }
  }

  static optionSortOptions = {
    best: {
      iteratees: ['score'],
      orders: ['desc'],
    },
    oldest: {
      iteratees: ['created'],
      orders: ['asc'],
    },
    newest: {
      iteratees: ['created'],
      orders: ['desc'],
    },
  }

  scrollToActiveAnswer = () => {
    if (this.scrollToRef && this.scrollToRef.current) {
      this.scrollToRef.current.scrollIntoView()
      window.scrollBy(0, -70)
    }
  }

  onSort = (option) => {
    this.setState({
      sortOrder: option.value,
    })
  }

  render() {
    const { sortOrder } = this.state
    const {
      answerProps,
      commentProps,
      answers,
      answersIsError,
      answersIsLoaded,
      numAnswers,
      questionId,
      scrollToAnswerId,
      stubbed,
    } = this.props

    if (answersIsError) {
      return (
        <div className={css.errorContainer}>
          <Body1>Something went wrong.</Body1>
          <Subtitle3>
            Try{' '}
            <Button onClick={refresh} type="text">
              refreshing
            </Button>{' '}
            the page in a couple of minutes.
          </Subtitle3>
        </div>
      )
    }

    const sortOptions = QuestionAnswers.optionSortOptions[sortOrder]
    const sortedAnswers = _.orderBy(
      answers,
      sortOptions.iteratees,
      sortOptions.orders
    )

    return (
      <div>
        <div className={css.sortContainer}>
          <div className={css.numAnswersContainer}>
            <Subtitle1>{numAnswers} Answers</Subtitle1>
            <ManageAnswersButton questionId={questionId} />
          </div>
          <div className={css.sortInput}>
            <Subtitle3 className={css.sortBy}>Sort by:</Subtitle3>
            <SimpleSelect
              className={css.select}
              onChange={this.onSort}
              options={[
                {
                  value: 'best',
                  label: 'Top Ranked',
                },
                {
                  value: 'newest',
                  label: 'Newest',
                },
                {
                  value: 'oldest',
                  label: 'Oldest',
                },
              ]}
              defaultValue={{ value: 'best', label: 'Top Ranked' }}
            />
          </div>
        </div>
        <div>
          {!answersIsLoaded ? (
            <StarLoader />
          ) : (
            sortedAnswers.map((answerObject, itemIndex) => {
              const { answerId } = answerObject

              return (
                <ErrorBoundary key={answerId}>
                  <AnswerInformation
                    answerId={answerId}
                    key={answerId}
                    className={css.answerInformation}
                    stubbed={stubbed}
                    itemIndex={itemIndex}
                    {...answerProps}
                    commentProps={commentProps}
                    forwardedRef={
                      answerId === scrollToAnswerId
                        ? this.scrollToRef
                        : undefined
                    }
                  />
                </ErrorBoundary>
              )
            })
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { questionId, scrollToAnswerId, stubbed, hash } = ownProps
  const { answers, numAnswers } = state.question.questions[questionId] || {}
  const answersRequest =
    (stubbed
      ? _.get(
          state.request.requests,
          makeRequestName(FETCH_CAMPAIGN_QUESTION_ANSWERS, hash)
        )
      : _.get(
          state.request.requests,
          makeRequestName(FETCH_QUESTION_ANSWERS, questionId)
        )) || {}

  return {
    numAnswers,
    scrollToAnswerId,
    answers:
      answers &&
      answers.map((answerId) =>
        _.pick(state.answer.answers[answerId], ['created', 'score', 'answerId'])
      ),
    answersIsLoaded: answersRequest.isLoaded,
    answersIsError: answersRequest.isError,
  }
}

export default connect(mapStateToProps)(QuestionAnswers)
