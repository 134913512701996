import React from 'react'

// Had to use this instead of react-intersection-observer because of IE :(
// https://stackoverflow.com/questions/61970655/intersectionobserver-undefined-for-internet-explorer
import 'intersection-observer'

import { pluralizeWord } from 'mednet-util/src/string'
import { Link2, Header2, Body1, Subtitle3 } from 'pharmacy/src/typography'
import { Card } from 'pharmacy/src/display/card'
import { Icon } from 'pharmacy/src/display/icon'
import { GoodQuestion } from 'pharmacy/src/question/goodQuestion'
import { FollowQuestionButton } from 'pharmacy/src/question/followQuestionButton'
import { UserUpdateInformation } from 'pharmacy/src/user/userUpdateInformation'
import { QuestionHighlight } from 'pharmacy/src/question/questionHighlight'
import { QuestionLink } from 'pharmacy/src/navigation/questionLink'
import { UpdatePreview } from 'pharmacy/src/question/updatePreview'

import { mixpanel } from 'mednet-util/src/tracking'

import { getLatestPublishedSponsorship } from 'mednet-util/src/sponsorship'

import ProgramStoryModeButton from '../programStoryModeButton'

import css from './questionCard.scss'

class QuestionCardWrapper extends React.Component {
  constructor(props) {
    super(props)

    this.containerRef = React.createRef()
  }

  componentDidMount() {
    const observer = new IntersectionObserver(
      (entries) => {
        entries &&
          entries.length &&
          this.props.updateVisibility?.(entries[0].intersectionRatio >= 0.5)
      },
      {
        threshold: 0.5,
      }
    )

    observer.observe(this.containerRef.current)
    this.setState({ observer })
  }

  componentDidUpdate(prevProps) {
    const { item, itemIndex } = this.props
    const { trackView, isVisible } = this.props

    if (trackView && isVisible && !prevProps.isVisible) {
      mixpanel.track('viewed_feed_item', {
        item_type: 'question_summary',
        item_position: itemIndex + 1,
        question_id: item.questionId,
        question_specialty: item.authorSpecialtyId,
        question_topics: item.questionTopicsIds,
        is_anonymous: item.isAnonymous ? true : false,
        is_starred: item.isFollowed ? true : false,
      })
    }
  }

  componentWillUnmount() {
    this.containerRef.current &&
      this.state.observer.unobserve(this.containerRef.current)
  }

  render() {
    const { children } = this.props

    return <div ref={this.containerRef}>{children}</div>
  }
}

class QuestionCard extends React.Component {
  static defaultProps = {
    questionLinkProps: {
      target: '_blank',
    },
    WrapperCompnent: React.Fragment,
  }

  constructor(props) {
    super(props)
    this.state = { isVisible: false }
    this.updateVisibility = this.updateVisibility.bind(this)
  }

  updateVisibility(newValue) {
    this.setState({ isVisible: newValue })
  }

  render() {
    const {
      item,
      questionLinkProps,
      feedName,
      itemIndex,
      trackViewedItems,
      enableProgramHighlight,
      useStarForFollow,
    } = this.props

    const {
      question,
      questionId,
      numAnswers,
      lastUpdate,
      hasPoll,
      sponsorships,
      tumorBoard,
    } = item

    let WrapperCompnent = this.props.WrapperCompnent
    let wrapperComponentProps = {}

    WrapperCompnent = QuestionCardWrapper
    wrapperComponentProps = {
      item,
      itemIndex,
      updateVisibility: this.updateVisibility,
      isVisible: this.state.isVisible,
      trackView: trackViewedItems,
    }

    const extendedQuestionLinkProps =
      feedName && Number.isInteger(itemIndex)
        ? {
            ...questionLinkProps,
            search: { src: `${feedName}_feed`, pos: itemIndex + 1 },
          }
        : questionLinkProps

    const latestPublishedSponsorship =
      getLatestPublishedSponsorship(sponsorships)

    //  sponsorship will be displayed when there is no tumor board
    const displayProgramLabel =
      enableProgramHighlight && latestPublishedSponsorship && !tumorBoard

    return (
      <WrapperCompnent {...wrapperComponentProps}>
        <Card>
          <div className={css.questionContainer}>
            <div className={css.rightActionsContainer}>
              <div className={css.saveButtonContainer}>
                <FollowQuestionButton
                  questionId={questionId}
                  feedName={feedName}
                  itemIndex={itemIndex}
                  useStar={useStarForFollow}
                />
              </div>
            </div>

            <div className={css.content}>
              <QuestionHighlight
                questionId={questionId}
                theme="card"
                enableProgramHighlight={enableProgramHighlight}
                trackingProps={{
                  location: `${feedName}_feed_item`,
                  item_position: itemIndex + 1,
                }}
              />
              <div className={css.row}>
                <Header2>
                  <QuestionLink
                    className={css.questionLink}
                    questionId={questionId}
                    {...extendedQuestionLinkProps}
                  >
                    {question}
                  </QuestionLink>
                </Header2>

                {displayProgramLabel && (
                  <div className={css.rightActionsContainer}>
                    <div>
                      <ProgramStoryModeButton
                        sponsorship={latestPublishedSponsorship}
                        isVisible={this.state.isVisible}
                        questionId={questionId}
                        trackingProps={{
                          location: `${feedName}_feed_item`,
                          item_position: itemIndex + 1,
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className={css.icons}>
                <GoodQuestion
                  questionId={questionId}
                  feedName={feedName}
                  itemIndex={itemIndex}
                />
                {hasPoll && (
                  <span>
                    <Icon className={css.pollIcon} icon={['far', 'signal']} />
                    <Subtitle3 className={css.iconText}>Poll</Subtitle3>
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className={css.updateContainer}>
            <UserUpdateInformation
              userId={lastUpdate.userId}
              dateUpdated={lastUpdate.dateUpdated}
              actionId={lastUpdate.actionId}
            >
              <UpdatePreview
                item={item.lastUpdate}
                TextComponent={Body1}
                questionLinkProps={{
                  className: css.moreLink,
                  ...extendedQuestionLinkProps,
                }}
              />
            </UserUpdateInformation>
          </div>
          {numAnswers > 0 && (
            <div className={css.numAnswerContainer}>
              <Link2>
                <QuestionLink
                  className={css.numAnswerLink}
                  questionId={questionId}
                  {...extendedQuestionLinkProps}
                >
                  See {numAnswers} {pluralizeWord(numAnswers, 'Answer')}
                </QuestionLink>
              </Link2>
            </div>
          )}
        </Card>
      </WrapperCompnent>
    )
  }
}

export default QuestionCard
