import React from 'react'

import { LoginForm } from 'components/LoginForm/loginForm'
import { LandingPageContent } from 'components/landingPageContent'

import css from './login.scss'

export const Login = ({ history }) => {
  return (
    <LandingPageContent>
      <div className={css.centeredContainer}>
        <LoginForm history={history} />
      </div>
    </LandingPageContent>
  )
}
