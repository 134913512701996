import React, { useCallback, useMemo } from 'react'
import { components } from 'react-select'
import Autosuggest from 'react-autosuggest'
import classNames from 'classnames'
import moment from 'moment-timezone'
import * as yup from 'yup'
import { merge } from 'lodash'

import { useModalDrivers } from 'mednet-cns/src/hooks/modal'

import {
  RECIPIENTS_MODAL,
  SCHEDULE_CAMPAIGN_MODAL,
} from 'mednet-util/src/constants/modal'

import TextInput from 'pharmacy/src/input/textInput/textInput'
import DatePicker from 'pharmacy/src/input/datePicker/datePicker'
import { Label1, Subtext } from 'pharmacy/src/typography'
import { TagSelect } from 'pharmacy/src/input/select'
import { Button, ButtonCheckbox } from 'pharmacy/src/input/button'
import { ConfirmationModal } from 'pharmacy/src/display/modal'

import { JOB_TYPE } from 'mednet-cns/src/reducers/systemJob'

import { RecipientsModal } from './recipientsModal'
import EmailBodyEditor from './emailBodyEditor'

import * as css from './campaign.scss'

export const CAMPAIGN_TYPE = {
  CAMPAIGN_PUBLICATION: 'P',
  CAMPAIGN_TRIAL: 'T',
  CAMPAIGN_EDIT_PROFILE: 'E',
  CAMPAIGN_TOPIC: 'O',
  CAMPAIGN_JOBS: 'J',
  CAMPAIGN_FB: 'F',
  CAMPAIGN_BATCH_INVITE: 'I',
  CAMPAIGN_INVITE_QUESTION: 'Q',
}

const campaignSchema = yup.object().shape({
  description: yup.string().required('Required'),
  schedule: yup.string(),
  sender: yup.string(),
  subject: yup.string(),
  recipients: yup.array(),
  template: yup.boolean(),
  body: yup.string(),
  saveAs: yup.string(),
  replyTo: yup.string().nullable(true),
  useInvitationIpPool: yup.boolean(),
})

const CampaignForm = ({
  values,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  sponsorshipId,
  campaignType,
  isDisabled,
  action,
}) => {
  const {
    description,
    schedule,
    sender,
    subject,
    recipients,
    template,
    body,
    sendPreviewTo,
    sendPreview,
    status,
    batchSize,
    reminders,
    remindersBodies,
    interval,
    sendReminders,
    replyTo,
    useInvitationIpPool,
  } = values

  const findRecipientWith = (id) => (recipient) => recipient.id === id

  const [openRecipientsModal] = useModalDrivers(RECIPIENTS_MODAL.modalId)
  const [openScheduleModal, closeScheduleModal] = useModalDrivers(
    SCHEDULE_CAMPAIGN_MODAL.modalId
  )

  const setValue = useCallback(
    (fieldName) => (value) => {
      setFieldValue(fieldName, value)
    },
    [setFieldValue]
  )

  const setCheckbox = useCallback((fieldName) => (e) => {
    if (e.target.checked) {
      setFieldValue(fieldName, true)
    } else {
      setFieldValue(fieldName, false)
    }
  })

  const setTime = useCallback(
    (value) => {
      const date = moment(schedule).format('YYYY-MM-DD')
      const time = value
      setFieldValue('schedule', `${date} ${time}`)
    },
    [setFieldValue, schedule]
  )

  const setDate = useCallback(
    (value) => {
      const date = moment(value).format('YYYY-MM-DD')
      const time = moment(schedule).format('HH:mm')
      setFieldValue('schedule', `${date} ${time}`)
    },
    [setFieldValue, schedule]
  )

  const setBody = useCallback((value) => {
    setFieldValue('body', value)
  })

  const setReminderBody = useCallback((reminderNo) => (value) => {
    setFieldValue(`remindersBodies[${reminderNo}]`, value)
  })

  const addRecipients = useCallback(
    ({
      selected = {},
      name,
      tree,
      isFileImport,
      file,
      filterUsersWithoutRecentActivity,
    }) => {
      const count = Object.values(selected).length

      if (count) {
        const label = name ? `${name} (${count})` : `theMednet users (${count})`
        const id = `new-${moment().unix()}`
        const { presetId: _presetId, ...relevantData } = tree

        setFieldValue('recipients', [
          ...recipients,
          {
            id,
            name: name || 'theMednet users',
            tree: relevantData,
            label,
            selected,
            isFileImport,
            file,
            filterUsersWithoutRecentActivity,
          },
        ])
      }
    },
    [recipients]
  )

  const updateRecipients = useCallback(
    ({
      id,
      selected = {},
      name,
      tree,
      isFileImport,
      file,
      filterUsersWithoutRecentActivity,
    }) => {
      const count = Object.values(selected).length
      const index = recipients.findIndex(findRecipientWith(id))
      const { presetId: _presetId, ...relevantData } = tree

      if (count && index > -1) {
        recipients[index] = {
          ...recipients[index],
          name: name || 'theMednet users',
          label: name ? `${name} (${count})` : `theMednet users (${count})`,
          selected,
          tree: relevantData,
          isFileImport,
          file,
          filterUsersWithoutRecentActivity,
        }
        setFieldValue('recipients', [...recipients])
      }
    },
    [recipients]
  )

  const addNewRecipientsModal = useCallback(
    (e) => {
      if (isDisabled) {
        return false
      }

      const nodeName = e.target?.nodeName?.toLowerCase()
      if (
        ['svg', 'path'].includes(nodeName) ||
        (nodeName === 'div' && e.target.getAttribute('role') === 'button')
      ) {
        return false
      }

      openRecipientsModal()
      return true
    },
    [isDisabled]
  )

  const removeRecipients = useCallback(
    (values) => {
      if (
        !isDisabled &&
        status !== 'progress' &&
        campaignType !== JOB_TYPE.REMINDERINVITE
      ) {
        setFieldValue('recipients', values)
      }
    },
    [recipients, isDisabled]
  )

  const RecipientsValueLabel = useCallback(
    (props) => {
      const { id } = props.data
      const handleEdit = (e) => {
        const recipientsGroup = recipients.find(findRecipientWith(id))
        openRecipientsModal({ recipients: recipientsGroup, id })

        e.stopPropagation()
      }

      return (
        <span className={css.recipientsValueLabel} onClick={handleEdit}>
          <components.MultiValueLabel {...props} />
          <Button className={css.editLabel} size="small" icon="pen" />
        </span>
      )
    },
    [recipients]
  )

  const totalUnique = useMemo(
    () =>
      Object.keys(
        recipients.reduce((carry, group) => merge(carry, group.selected), {})
      ).length,
    [recipients]
  )

  const suggestions = useMemo(
    () => [
      'theMednet Journal Club <journalclub@themednet.org>',
      'theMednet Update <update@themednet.org>',
      'ACR / theMednet Journal Club <journalclub@themednet.org>',
      sender,
    ],
    [Boolean(sender)]
  )
  const renderSuggestion = useCallback((suggestion) => (
    <div className={css.suggestion}>{suggestion}</div>
  ))
  const nameLabel =
    campaignType === JOB_TYPE.INVITE || campaignType === JOB_TYPE.REMINDERINVITE
      ? 'Invitation campaign name:'
      : 'Campaign name:'
  const numberOfChunks = Math.ceil(totalUnique / batchSize)

  const remindersElements = []

  for (let i = 0; i < reminders; i++) {
    remindersElements.push(
      <div key={i} className={css.row}>
        <Label1>Additional text for reminder no. {i + 1}</Label1>
        <div className={classNames(css.row, css.texteditor)}>
          <EmailBodyEditor
            sponsorshipId={sponsorshipId}
            value={remindersBodies[i]}
            onEditorChange={setReminderBody(i)}
            campaignType={campaignType}
            disabled={isDisabled}
          />
        </div>
      </div>
    )
  }

  return (
    <form>
      <div className={classNames(css.row)}>
        <div className={classNames(css.cell, css.statusCell)}>
          <Label1 className={css.label}>Status:</Label1>
          <Label1>{status}</Label1>
        </div>
        <div className={classNames(css.cell, css.actions)}>
          {action === 'edit' ? (
            <Button
              className={css.action}
              rightIcon={!sendPreview ? 'save' : 'eye'}
              isDisabled={!body}
              onClick={() => {
                setFieldValue('saveAs', 'preview', false)
                handleSubmit()
              }}
            >
              Save{!sendPreview ? ' and stay' : ', send preview and stay'}
            </Button>
          ) : null}
          <Button
            className={css.action}
            rightIcon="save"
            isLoading={isSubmitting}
            isDisabled={!description || isDisabled}
            onClick={() => {
              setFieldValue('saveAs', 'draft', false)
              handleSubmit()
            }}
          >
            Save as draft
          </Button>
          <Button
            className={css.action}
            rightIcon="paper-plane"
            isLoading={isSubmitting}
            isDisabled={!description || isDisabled}
            onClick={() => {
              setFieldValue('saveAs', 'schedule', false)
              openScheduleModal()
            }}
          >
            Schedule
          </Button>
        </div>
      </div>
      <div className={classNames(css.row, css.header)}>
        <div className={css.campaignNameCell}>
          <Label1 className={classNames(css.label, css.required)}>
            {nameLabel}
          </Label1>
          <TextInput
            className={css.input}
            placeholder="Campaign name..."
            showClear={false}
            value={description}
            name="description"
            onChange={setValue('description')}
            disabled={isDisabled}
          />
        </div>
      </div>
      <div className={classNames(css.row, css.campaignSettings)}>
        <div className={css.cell}>
          <Label1 className={css.label}>Schedule:</Label1>
          <div className={css.schedule}>
            <DatePicker
              className={classNames(css.date, css.input)}
              value={moment(schedule).format('YYYY-MM-DD')}
              defaultValue={moment(schedule).format('YYYY-MM-DD')}
              onChange={setDate}
              dayPickerProps={{
                disabledDays: [
                  {
                    before: new Date(),
                  },
                  {
                    daysOfWeek:
                      campaignType === JOB_TYPE.INVITE ? [0, 6] : undefined,
                  },
                ],
              }}
              inputProps={{
                disabled:
                  isDisabled ||
                  campaignType === JOB_TYPE.REMINDERINVITE ||
                  status === 'progress',
              }}
            />
            <TextInput
              className={classNames(css.time, css.input)}
              placeholder="time"
              showClear={false}
              inputType="time"
              value={moment(schedule).format('HH:mm')}
              onChange={setTime}
              disabled={
                isDisabled ||
                campaignType === JOB_TYPE.REMINDERINVITE ||
                status === 'progress'
              }
            />
            <div className={css.zone}>
              {moment.tz(schedule, 'America/New_York').format('zz')}
            </div>
          </div>
        </div>
        {campaignType === JOB_TYPE.INVITE && (
          <div className={classNames(css.cell, css.autoReminderCell)}>
            <ButtonCheckbox
              className={css.checkbox}
              buttonProps={{ size: 'small' }}
              selectText="Send reminders"
              unselectText="Send reminders"
              inputProps={{
                checked: sendReminders,
                onChange: (e) => {
                  setCheckbox('sendReminders')(e)
                  if (sendReminders) {
                    setValue('reminders')(0)
                  } else {
                    setValue('reminders')(1)
                  }
                },
              }}
            />
            {sendReminders && (
              <div className={css.autoReminderWrapper}>
                <Label1 className={css.label}>Send</Label1>
                <TextInput
                  className={classNames(css.input, css.autoReminderInput)}
                  showClear={false}
                  inputType="number"
                  value={reminders}
                  min={1}
                  disabled={status !== 'draft' && status !== 'scheduled'}
                  onChange={setValue('reminders')}
                />{' '}
                <Label1 className={css.label}>reminder(s) between</Label1>
                <TextInput
                  className={classNames(css.input, css.autoReminderInput)}
                  showClear={false}
                  inputType="number"
                  value={interval}
                  min={1}
                  disabled={status !== 'draft' && status !== 'scheduled'}
                  onChange={setValue('interval')}
                />{' '}
                <Label1 className={css.label}>day(s)</Label1>
              </div>
            )}
          </div>
        )}
        {(campaignType === JOB_TYPE.INVITE ||
          campaignType === JOB_TYPE.REMINDERINVITE) && (
          <div className={css.cell}>
            <Label1 className={css.label}>
              Split users, recipient # per batch:
            </Label1>
            <TextInput
              className={classNames(css.input, css.batchInput)}
              showClear={false}
              placeholder="All in one go"
              inputType="number"
              value={batchSize}
              min={1}
              disabled={
                !(recipients && recipients.length) ||
                (status !== 'draft' && status !== 'scheduled') ||
                campaignType === JOB_TYPE.REMINDERINVITE ||
                status === 'progress'
              }
              onChange={setValue('batchSize')}
            ></TextInput>
          </div>
        )}
      </div>
      <div className={css.row}>
        <div className={classNames(css.cell, css.batchInfoCell)}>
          {campaignType === JOB_TYPE.INVITE &&
            Boolean(batchSize) &&
            numberOfChunks < 21 &&
            `${numberOfChunks} batches span ${numberOfChunks} weekdays`}
          {campaignType === JOB_TYPE.INVITE &&
            Boolean(batchSize) &&
            numberOfChunks > 20 &&
            'Please change the number of users per batch, invites cannot span more than 20 weekdays'}
        </div>
      </div>
      {isDisabled ? null : (
        <div className={classNames(css.row, css.previewRecipients)}>
          <div className={css.cell}>
            <ButtonCheckbox
              buttonProps={{ size: 'small' }}
              selectText="Send preview to (semicolon separated email address list):"
              unselectText="Send preview to (semicolon separated email address list):"
              inputProps={{
                checked: sendPreview,
                onChange: setCheckbox('sendPreview'),
              }}
              className={css.checkbox}
            />
            <TextInput
              className={css.input}
              showClear={false}
              value={sendPreviewTo}
              disabled={!sendPreview}
              onChange={setValue('sendPreviewTo')}
            />
          </div>
        </div>
      )}
      <div className={css.row}>
        <div className={classNames(css.oneline, css.full)}>
          <Label1 className={css.label}>From:</Label1>
          <div className={css.autosuggest}>
            <Autosuggest
              onSuggestionsFetchRequested={() => suggestions}
              onSuggestionsClearRequested={() => suggestions}
              getSuggestionValue={(val) => val}
              renderSuggestion={renderSuggestion}
              renderInputComponent={(props) => (
                <TextInput
                  onChangeHandlesEvent
                  {...props}
                  disabled={
                    isDisabled ||
                    campaignType === JOB_TYPE.REMINDERINVITE ||
                    status === 'progress'
                  }
                />
              )}
              suggestions={suggestions}
              inputProps={{
                className: css.input,
                value: sender,
                onChange: (_e, { newValue }) => setValue('sender')(newValue),
                showClear: false,
              }}
              shouldRenderSuggestions={() => true}
              focusInputOnSuggestionClick={false}
            />
          </div>
        </div>
      </div>
      <div className={css.row}>
        <div className={classNames(css.oneline, css.full)}>
          <Label1 className={css.label}>Subject:</Label1>
          <TextInput
            className={css.input}
            showClear={false}
            value={subject}
            onChange={setValue('subject')}
            disabled={
              isDisabled ||
              campaignType === JOB_TYPE.REMINDERINVITE ||
              status === 'progress'
            }
          />
        </div>
      </div>
      <div className={css.row}>
        <div
          className={classNames(css.oneline, css.full)}
          onClick={addNewRecipientsModal}
        >
          <div className={css.label}>
            <Label1>To:</Label1>
            <Subtext># {totalUnique}</Subtext>
          </div>
          <div className={css.select}>
            <TagSelect
              creatable
              isMulti
              value={recipients}
              isClearable={false}
              components={{ MultiValueLabel: RecipientsValueLabel }}
              onChange={removeRecipients}
              getOptionValue={(data) => data.id}
            />
          </div>
        </div>
      </div>
      <div className={css.row}>
        <div className={classNames(css.oneline, css.full)}>
          <Label1 className={css.label}>Reply to:</Label1>

          <div className={css.autosuggest}>
            <Autosuggest
              onSuggestionsFetchRequested={() => suggestions}
              onSuggestionsClearRequested={() => suggestions}
              getSuggestionValue={(val) => val}
              renderSuggestion={renderSuggestion}
              renderInputComponent={(props) => (
                <TextInput
                  onChangeHandlesEvent
                  {...props}
                  disabled={
                    isDisabled ||
                    campaignType === JOB_TYPE.REMINDERINVITE ||
                    status === 'progress'
                  }
                />
              )}
              suggestions={suggestions}
              inputProps={{
                className: css.input,
                value: replyTo || '',
                onChange: (_e, { newValue }) =>
                  setValue('replyTo')(newValue?.trim()),
                showClear: false,
              }}
              shouldRenderSuggestions={() => true}
              focusInputOnSuggestionClick={false}
            />
          </div>
        </div>
      </div>
      <div className={css.row}>
        <div className={classNames(css.oneline, css.full)}>
          <Label1 className={css.label}>In template:</Label1>
          <ButtonCheckbox
            buttonProps={{ size: 'small' }}
            selectText=""
            unselectText=""
            inputProps={{
              checked: template,
              onChange: setCheckbox('template'),
              disabled:
                isDisabled ||
                campaignType === JOB_TYPE.REMINDERINVITE ||
                status === 'progress',
            }}
          />
        </div>
      </div>
      {campaignType !== JOB_TYPE.CAMPAIGN && (
        <div className={css.row}>
          <div className={classNames(css.oneline, css.full)}>
            <Label1 className={classNames(css.label, css.wideLabel)}>
              Use Invitations IP Pool:
            </Label1>
            <ButtonCheckbox
              buttonProps={{ size: 'small' }}
              selectText=""
              unselectText=""
              inputProps={{
                checked: useInvitationIpPool,
                onChange: setCheckbox('useInvitationIpPool'),
                disabled:
                  isDisabled ||
                  campaignType === JOB_TYPE.REMINDERINVITE ||
                  status === 'progress',
              }}
            />
          </div>
        </div>
      )}
      <div className={css.row}>
        {campaignType === JOB_TYPE.REMINDERINVITE && (
          <Label1>
            Text added on top of the previous reminder and/or main invite
            campaign:
          </Label1>
        )}
      </div>
      <div className={classNames(css.row, css.texteditor)}>
        <EmailBodyEditor
          sponsorshipId={sponsorshipId}
          value={body}
          onEditorChange={setBody}
          campaignType={campaignType}
          disabled={isDisabled}
        />
      </div>
      {remindersElements.map((reminderElement) => reminderElement)}
      <RecipientsModal
        onAdd={addRecipients}
        onSave={updateRecipients}
        queryUserStatus={
          campaignType === JOB_TYPE.INVITE ||
          campaignType === JOB_TYPE.REMINDERINVITE
            ? 'unregistered'
            : 'active'
        }
        isDisabled={
          isDisabled ||
          campaignType === JOB_TYPE.REMINDERINVITE ||
          status === 'progress'
        }
      />
      <ConfirmationModal
        closeText="Cancel"
        closeType="neutral"
        header={`Are you sure you want to schedule the campaign? (${moment
          .tz(schedule, 'America/New_York')
          .format('YYYY-MM-DD hh:mm a zz')})`}
        modalId={SCHEDULE_CAMPAIGN_MODAL.modalId}
        onSubmit={() => {
          closeScheduleModal()
          handleSubmit()
        }}
        submitText="Schedule"
        submitType="primary"
        size="larger"
      />
    </form>
  )
}

export { CampaignForm, campaignSchema }
