import React from 'react'
import { connect } from 'react-redux'

import { UserImageContainer } from 'pharmacy/src/user/userImage'

import UserUpdateText from './userUpdateText'

class UserUpdateInformation extends React.Component {
  render() {
    const {
      user,
      imageSize,
      useUserProfileLink,
      children,
      onProfileClick,
      ...textProps
    } = this.props
    const { userId } = user || {}

    return (
      <UserImageContainer
        user={user}
        imageSize={imageSize}
        useUserProfileLink={useUserProfileLink}
        onProfileClick={onProfileClick}
      >
        <div>
          <UserUpdateText
            userId={userId}
            user={user}
            {...textProps}
            onProfileClick={onProfileClick}
          />
          {children}
        </div>
      </UserImageContainer>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: ownProps.user || state.user.users[ownProps.userId] || {},
  }
}

export default connect(mapStateToProps)(UserUpdateInformation)
