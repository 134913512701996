import { createSelector } from 'reselect'

import {
  fetchUserMocProfile,
  fetchUserProfile,
  fetchUserWithPermissions,
  getQueryRequestId,
  queryActiveUsers,
  queryUsers,
  queryUnregisteredUsers,
  fetchUserMOCProfileByID,
} from 'mednet-cns/src/reducers/user'
import { createDataHook } from 'mednet-cns/src/hook-factory'
import { useSelector } from 'react-redux'
import { useMemo } from 'react'

export const [useUsersFromQuery, useUsersFromQueryLazy] = createDataHook(
  queryUsers,
  createSelector(
    (state) => state.user || {},
    (_state, { requestId }) => getQueryRequestId(requestId),
    (user, requestId) => {
      return user.queries[requestId] || []
    }
  )
)

export const [useActiveUsersFromQuery, useActiveUsersFromQueryLazy] =
  createDataHook(
    queryActiveUsers,
    createSelector(
      (state) => state.user || {},
      (_state, { requestId }) => getQueryRequestId(requestId),
      (user, requestId) => {
        return user.queries[requestId] || []
      }
    )
  )

export const [
  useUnregisteredUsersFromQuery,
  useUnregisteredUsersFromQueryLazy,
] = createDataHook(
  queryUnregisteredUsers,
  createSelector(
    (state) => state.user || {},
    (_state, { requestId }) => getQueryRequestId(requestId),
    (user, requestId) => {
      return user.queries[requestId] || []
    }
  )
)

export const [useUserProfile, useUserProfileLazy] = createDataHook(
  fetchUserProfile,
  createSelector(
    (state) => {
      return state.user?.users || {}
    },
    (_state, { userId }) => userId,
    (users, userId) => {
      return users[userId]
    }
  )
)

// This is for logged in users
export const [useUser, useUserLazy] = createDataHook(
  fetchUserWithPermissions,
  createSelector(
    (state) => state.user,
    (user) => {
      return user ? { userData: user.data, permissions: user.permissions } : {}
    }
  )
)

export const [useUserMocProfile, useUserMocProfileLazy] = createDataHook(
  fetchUserMocProfile,
  createSelector(
    (state) => state.user.data,
    (userData) => {
      return userData
    }
  )
)

export const [useUserMocProfileById, useUserMocProfileLazyById] =
  createDataHook(
    fetchUserMOCProfileByID,
    createSelector(
      (state) => {
        return state.user?.mocProfile || {}
      },
      (_state, { userId }) => userId,
      (mocProfile, userId) => {
        return mocProfile[userId]
      }
    )
  )

export const useSelectUserById = (userId) => {
  const selectUser = useMemo(
    () =>
      createSelector(
        (state) => state.user.users,
        (_, userId) => userId,
        (users, userId) => users[userId]
      ),
    []
  )

  const user = useSelector((state) => selectUser(state, userId))
  return user
}
