import React, { useEffect, useState } from 'react'
import * as yup from 'yup'
import { Formik } from 'formik'
import { Header2, Header3, Subtitle1, Subtitle3 } from 'pharmacy/src/typography'
import { useDispatch } from 'react-redux'
import classNames from 'classnames'

import { TextInput } from 'pharmacy/src/input/textInput'
import { Button } from 'pharmacy/src/input/button'
import { Icon } from 'pharmacy/src/display/icon'

import { blockInvites } from 'mednet-cns/src/reducers/user'

import { mixpanel } from 'mednet-util/src/tracking'

import css from './index.scss'

const schema = yup.object().shape({
  targetEmailAddress: yup
    .string()
    .email('Invalid email')
    .max(128, 'Too long')
    .required('Required'),
})

export const SubmitInvitesUnsubscription = (props) => {
  const params = new URLSearchParams(props.location.search)
  const userEmail = params.get('user_email')
  const token = params.get('token')
  const invitationId = params.get('invitation_id')

  const dispatch = useDispatch()

  const [isSubmitted, setSubmitted] = useState(false)

  const initialValues = {
    contactedEmailAddress: userEmail,
    targetEmailAddress: userEmail,
    invitationId,
    token,
  }

  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(true)
    dispatch(
      blockInvites(values, () => {
        setSubmitting(false)
        setSubmitted(true)
      })
    )
  }

  useEffect(() => {
    mixpanel.track('viewed_page', { page_viewed: 'stop_invites' })
  }, [])

  if (isSubmitted) {
    return (
      <div className={css.centeredContainer}>
        <Header2>
          <Icon icon="check-circle" className={css.checkIcon} />
          Your request was submitted
        </Header2>
      </div>
    )
  }

  return (
    <div className={css.centeredContainer}>
      <div className={css.row}>
        <Header3>Stop receiving invitations from theMednet</Header3>
        <hr className={css.divider} />
      </div>

      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={(values, formikHelpers) => {
          handleSubmit(values, formikHelpers)
        }}
      >
        {(formikProps) => {
          return (
            <>
              <div className={css.row}>
                <Subtitle1>Email</Subtitle1>
                <TextInput
                  value={formikProps.values.targetEmailAddress}
                  onChange={(value) =>
                    formikProps.setFieldValue('targetEmailAddress', value)
                  }
                  className={classNames(css.emailField, {
                    [css.emailFieldWithError]: Boolean(
                      formikProps.errors['targetEmailAddress']
                    ),
                  })}
                  showClear={false}
                />

                {formikProps.errors['targetEmailAddress'] && (
                  <Subtitle3 className={css.errorMessage}>
                    {formikProps.errors['targetEmailAddress']}
                  </Subtitle3>
                )}
              </div>
              <div className={css.row}>
                <div className={css.buttonContainer}>
                  <Button
                    type="primary"
                    className={css.button}
                    isLoading={formikProps.isSubmitting}
                    onClick={formikProps.handleSubmit}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </>
          )
        }}
      </Formik>
    </div>
  )
}
